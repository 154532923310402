.gallery-contact-nav-bar-ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 0;

  .gallery-contact-nav-bar-li {
    padding: 1rem;

    &:hover {
      background-color: $dark;
      color: $light;
      cursor: pointer;
    }
    span {
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.gallery-images-container {
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 5rem;

  .gallery-image-content {
    position: relative;
    overflow: hidden;
    animation: popout 300ms;
    animation-timing-function: ease-in;

    .gallery-image-content-img {
      height: 100%;
      max-width: 100%;

      object-fit: cover;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }
}

.active-filter {
  background-color: $dark;
  color: $light;
  cursor: pointer;
}

@keyframes popout {
  0% {
    transform: scale(0.3);
    opacity: 0.2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .gallery-images-container {
    flex-direction: column !important;

    .gallery-image-content {
      width: 100% !important;
    }
  }
  .gallery-head-banner-text {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .gallery-head-banner {
    height: 13rem;
  }
  .gallery-images-container {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)) !important;
  }
}
