.contactus-container-main {
  padding: 2rem 0;
  gap: 1rem;

  .contactus-container-left {
    width: 50%;
  }
  .contactus-contacts {
    padding: 1rem 0;
  }
  .contactus-contacts-item {
    margin: 1rem 0;
    overflow: hidden;

    .contactus-contacts-item-icon {
      color: $primary;
      background: $secondary;
      padding: 1rem;
      border-radius: 8px;
      margin-right: 1rem;
      cursor: pointer;
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    .contactus-contacts-item-text {
      h3 {
        color: #685757;
      }
      a {
        color: $info;
        font-weight: 450;
      }
    }
  }
  .contactus-container-right-contactform {
    margin: 1rem 0;
  }

  .contactus-container-right-title,
  .contactus-container-left-title {
    font-size: 2rem;
    padding: 0.5rem 0;
  }

  .contactus-container-right {
    width: 50%;

    .contactus-container-right-contactform-span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1rem 0;
      gap: 1rem;
    }
  }
}
.contactus-container-left-desc {
  font-weight: 500;
  word-spacing: 2px;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
}

.contactus-container-right-btn {
  button {
    height: 3rem;
    width: 12rem;
    border-radius: 8px;
    background-color: $primary;
    color: $light;
    font-weight: bold;

    &:hover {
      background-color: $secondary;
      color: $primary;
    }
  }
}

.contactus-map {
  height: 50vh;
  width: 100%;
  margin: 2rem 0;
  border: 3px solid #ffd1d1;
}

@media screen and (max-width: 900px) {
  .contactus-container-main {
    flex-direction: column;
    gap: 1rem;
    .contactus-container-left {
      width: 100%;
    }
    .contactus-container-right {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .contactus-container-right-contactform-span {
    flex-direction: column !important;
    gap: 1rem !important;
  }
  .contactus-head-banner {
    height: 13rem !important;
  }
  .contactus-head-banner-title {
    font-size: 1.5rem !important;
  }
}
