// color group

$primary: #d53f34;
$secondary: #21293b;
$tertiary: #54595f;
$light: #f5f5f5;
$dark: #141414;
$danger: #ff3628;
$success: #00b894;
$warning: #ffdd57;
$info: #34a3d5;
$transparent: #00000000;

$base-padding: 1rem;

// --font size---

$font-size-xl: 3rem;

$letter-spacing: 3px;
