@import url("https://fonts.googleapis.com/css2?family=Spline+Sans&display=swap");

.navbar-main-container {
  background-color: $light;
  font-family: "Spline Sans", sans-serif;
}

.nav-down-navigation-left {
  a {
    border: none !important;
  }
}
.donatenow-navlink {
  border: none !important;
}
.nav-up-contact {
  background-color: $secondary;
  color: $light;
}
.active {
  border-bottom: 3px solid $danger;
  padding: 9px 0;
}
.nav-up-contact-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 1rem 0 !important;
  .nav-up-contact-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    .nav-up-contact-left-ul {
      display: flex;
      flex-direction: row;
      margin-left: 1rem;
      li {
        display: flex;
        padding-right: 1rem;
        align-items: center;
        a {
          display: flex;
          color: $light;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
  .nav-up-contact-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .nav-up-contact-right-span {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }
  }
}

.navbar-hoverable-li {
  position: relative;

  .navbar-child-hover-div {
    display: none;
    position: absolute;
    z-index: 5;

    background: $light;
    width: 16rem;
    padding: 1rem;
    top: 2rem;
    left: 0;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    .navbar-child-hover-span {
      color: $dark;
      word-spacing: 3px;
      letter-spacing: 0.5px;
      padding: 1rem;
      border-radius: 0.5rem;
      &:hover {
        background-color: #21293b1a;
        color: $primary;
      }
      a {
        border: none;
      }
    }
  }
  &:hover {
    .navbar-child-hover-div {
      display: flex !important;
    }
  }
}

.nav-down-navigation-right-button {
  span {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
  }
}

.nav-down-navigation {
  background-color: $light;
  .nav-down-navigation-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 1rem 0 !important;
    .nav-down-navigation-right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .nav-down-navigation-right-ul {
        display: flex;
        flex-direction: row;
        margin: 0 1rem;
        li {
          padding: 0.5rem;
          color: $dark;
          font-weight: 500;
          cursor: pointer;
          letter-spacing: 1px;
          font-size: 0.9rem;
          &:hover {
            color: $primary;
          }
          a {
            color: $dark;
            &:hover {
              color: $primary;
            }
          }
        }
      }
      .nav-down-navigation-right-button {
        height: 3rem;
        width: 10rem;

        background: linear-gradient(45deg, #db1c0f, #ff3022);
        color: $light;
        position: relative;
        top: 0;
        transition: all 0.2s ease-in !important;

        &:hover {
          background: $secondary;

          top: -2%;
        }
      }
    }
  }
}

.responsive-nav-navigation {
  padding-left: 1rem;
}

.responsive-navbar {
  display: none;
  justify-content: center;
  align-items: center;
}
.responsive-nav-logo {
  padding: 2rem 0;
  position: relative;
}
.responsive-navbar-right-nav-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  // min-height: 100vh;
  min-width: 300px !important;
}

.responsive-nav-navigation-ul li {
  padding: 0.5rem 0;

  a {
    font-weight: 600;
    cursor: pointer;
    color: $dark;
  }
}

.responsive-navbar-social-ul {
  padding: 1rem 0.2rem;
  justify-content: space-evenly;
}

.responsive-nav-navigation-ul-li-accordion-summary {
  h1 {
    font-size: 1rem;
    font-weight: 500;
    color: $dark;
  }
}

.responsive-nav-accodion {
  padding: 0 !important;
  box-shadow: none !important;
  div {
    padding: 0 !important;
  }
  .responsive-nav-navigation-ul-li-accordion-details-div {
    padding: 0 !important;
    .responsive-nav-navigation-ul-li-accordion-details-div-span {
      padding: 1rem 0;
    }
  }
}

.accordion-li {
  background-color: none !important;
  padding: 0 !important;
}

.responsive-nav-close-now {
  height: 2.5rem;
  width: 8rem;
  background: $danger;
  border-radius: 0.5rem;

  font-size: 1rem;
  font-weight: 500;

  color: $light;
}

@media screen and (max-width: 950px) {
  .responsive-navbar {
    display: flex;
  }
  .nav-down-navigation-right {
    display: none !important;
  }
  .nav-up-contact {
    display: none !important;
  }
  .nav-down-navigation-left {
    padding-left: 0.5rem;
  }
}
