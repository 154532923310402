.services-container {
  background-color: $light;
}
.services-content-main {
  padding: 5rem 0;
  .services-content-main-heading {
    padding: 2rem 0;
    gap: 1rem;
    .services-content-main-img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 20rem;
        object-fit: cover;
      }
    }
    .services-content-main-title {
      h1 {
        font-size: 3rem;
      }
      p {
        width: 90%;
        padding: 1rem 0;
      }
    }
  }
}
.services-programme {
  text-align: center;
  font-size: 1.8rem;
  padding: 1rem 0;
}

.all-services {
  margin-top: 3rem;
  gap: 1rem;
  background-color: $dark;
  .service-content-all-services-child {
    gap: 1rem;

    color: $light;
    padding: 1rem;
    font-size: 1.5rem;
    &:hover {
      background: linear-gradient(45deg, #d13838, #ff1c1cd6);
      cursor: pointer;
    }

    .service-content-all-services-child-img {
      img {
        height: 12rem;
        width: 18rem;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .services-head-banner {
    h1 {
      font-size: 2rem;
    }
  }
  .services-content-main-title {
    h1 {
      font-size: 2.5rem !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .services-head-banner {
    h1 {
      font-size: 1.8rem;
    }
  }
  .services-content-main-title {
    h1 {
      font-size: 2rem !important;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .services-content-main-heading {
    flex-direction: column !important;
  }
  .services-content-main-img {
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .services-content-main-title {
    h1 {
      font-size: 1.8rem !important;
      text-align: center;
    }
  }
  .services-head-banner {
    h1 {
      font-size: 1.2rem;
    }
  }
  .aboutus-services-flex-div {
    flex-direction: column !important;
  }
  .service-content-all-services-child-img {
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .service-content-all-services-child-desc {
    font-size: 1.2rem !important;
  }
  .services-content-main {
    padding: 0 !important;
  }

  .services-programme {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 500px) {
  .services-head-banner {
    height: 13rem !important;
  }
}
