// default scss for li a etc

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
}

p {
  text-align: justify;
}

.w-100 {
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.btn-primary {
  height: 3rem;
  font-weight: 600 !important;
  width: 10rem;
  background-color: #f3291c !important;
  color: $light !important;
}

.btn-primary-outline {
  height: 3rem;
  width: 10rem;
  font-weight: 600 !important;
  background-color: $transparent !important;
  border: 1px solid #f3291c !important;
  color: $primary !important;
}

@for $i from 1 through 2 {
  .gap-#{$i} {
    gap: #{$i}rem;
  }
}
