.slider-child {
  min-width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
}
.slide-main-container {
  position: relative;
}
.left-right-slide-btn {
  // display: none;
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
}
.left-right-slide-btn-span {
  position: relative;
  width: 3rem;
  height: 3rem;
  color: #d53f34;
  background: #ffffff61;
  margin: 2px;
  border: 1px solid $light;
  border-radius: 50%;
  z-index: 100;
  // left: 0;
  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: #f5f5f5;
    background: #ff00005c;
    cursor: pointer;
    border: 1px solid $primary;
  }
}
.slide-main-container-children {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  scroll-behavior: smooth;
  justify-content: flex-start;

  scroll-snap-type: x mandatory;
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  scroll-padding: 0px;
}
.slide-main-container-children {
  scrollbar-width: none;
  position: relative;
  height: 40rem;
}

.slide-main-container-children::-webkit-scrollbar {
  display: none;
  //   scrollbar-width: none;
}

.slider-image {
  position: absolute;
  top: 0;

  width: 100%;
  z-index: -1;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.6);
  }
}

///////////////////////////////////////////////////////////
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: fade;
  animation-duration: 1s;
  // animation-iteration-count: infinite;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.slider-content {
  position: absolute;
  top: 20%;
  .slider-content-p {
    color: $light;
    font-size: 1.5rem;
  }
  .slider-content-h1 {
    color: $light;
    font-size: 4.5rem;
    font-weight: 700;
    padding: 1rem 0;
    text-transform: none;
    width: 50rem;
    letter-spacing: $letter-spacing;
  }
}

.slider-btn {
  position: relative;
  bottom: 210px;
  .slide-left-btn,
  .slide-right-btn {
    margin: 0.5rem 0;
    width: 10rem;
    height: 3rem;
    //   border-radius: 0.5rem !important;
    color: $light !important;
    margin-right: 2rem !important;
    font-weight: 600 !important;
    &:hover {
      background-color: #21293b !important;
    }
  }
  .slide-main-container-inner {
    position: relative;
  }

  .slide-left-btn {
    background: linear-gradient(#45c1f9, #00b2ff) !important;
    margin-right: 1rem !important;
    filter: drop-shadow(2px 4px 6px #00000060);
  }
  .slide-right-btn {
    background: linear-gradient(#ff1100, #ff2314) !important;
    margin-right: 1rem !important;
    filter: drop-shadow(2px 4px 6px #00000060);
  }
}

@media screen and (max-width: 850px) {
  .slider-content-h1 {
    font-size: 3rem !important;
    width: 30rem !important;
  }
  .slider-content-p {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 600px) {
  .slider-content-h1 {
    font-size: 2.5rem !important;
    width: 15rem !important;
  }
  .slider-content-p {
    font-size: 1rem !important;
  }
  .left-right-slide-btn {
    display: none !important;
  }
}
