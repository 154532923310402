.page-header {
  background-image: url("../asset/common/common-banner-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 22rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .page-header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $light;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    &::after {
      content: "";
      display: block;
      border-bottom: 5px solid $primary;
    }
  }
}

@media screen and (max-width: 700px) {
  .page-header {
    height: 20rem !important;
  }
  .page-header-title {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 400px) {
  .page-header {
    height: 15rem !important;
  }
  .page-header-title {
    font-size: 1.3rem !important;
  }
}
