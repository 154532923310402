.aboutus-container-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 7rem 0;
  gap: 1rem;
  .aboutus-container-main-left {
    display: block;

    padding: 1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .aboutus-container-main-right {
    height: 100%;

    .aboutus-container-main-right-title {
      font-size: 2.5rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      margin-bottom: 1rem;
    }
    .aboutus-container-main-right-text {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;
    }
  }
}

.aboutus-container-main-left,
.aboutus-container-main-right {
  width: 50%;
}

.aboutus-team {
  padding: 5rem 0;

  .aboutus-team-main {
    flex-wrap: wrap;
    gap: 1rem;
    .aboutus-team-child {
      width: 22%;
      .aboutus-team-child-img {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .aboutus-team-child-desc {
        background-color: $dark;
        padding: 1rem;
        color: $light;
        width: 100%;
        height: 6rem;
        .aboutus-team-name {
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .aboutus-container-main {
    flex-direction: column !important;
  }
  .aboutus-container-main-left,
  .aboutus-container-main-right {
    width: 100% !important;
  }

  .aboutus-container-main-right-title {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 800px) {
  .aboutus-team-main {
    flex-direction: column !important;
    align-items: center !important;
  }
  .aboutus-team-child {
    width: 50% !important;
  }
}
@media screen and (max-width: 600px) {
  .aboutus-team-main {
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .aboutus-header {
    height: 13rem;
  }
  .aboutus-header-title {
    font-size: 1.5rem !important;
  }
  .aboutus-team-child-img {
    width: 100% !important;
    height: 100% !important;
  }
  .aboutus-team-child-desc {
    width: 100% !important;
  }
  .aboutus-team-child {
    width: 100% !important;
  }
  .aboutus-container-main {
    padding: 3rem 0 !important;
  }
}
