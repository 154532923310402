.sticky-slide-to-top {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 999;
  background-color: $primary;
  width: 3rem;
  height: 3rem;
  border-radius: 0.3rem;
  color: $light;
  cursor: pointer;
}

.home-main-container {
  background-color: $transparent;
  font-family: "Spline Sans", sans-serif;
}

.home-motto {
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-motto-heading {
  padding: 2rem 0;
  p {
    font-size: 1.5rem;
    color: $secondary;
    text-align: center;
    font-family: "Island Moments", cursive;
  }
  h1 {
    font-size: 3rem;
    color: $primary;
    padding: 1rem 0;
    font-weight: 600;
    text-align: center;
    letter-spacing: 3px;
  }
}

.home-contribution-banner {
  background-image: url("../../asset/home/home-banner-1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 7rem 0;
  .home-contribution-banner-title {
    color: $light;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
  }
  .home-contribution-banner-btn {
    margin-top: 2rem;
    border: 1px solid $transparent;
    &:hover {
      background-color: $secondary !important;
      color: $light;
    }
  }
}

.home-mission-banner {
  background-color: $light;
  padding: 7rem 0;
  .home-mission-banner-heading-h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    color: $primary;
    margin: 1rem 0;
    letter-spacing: $letter-spacing;
  }
}

.home-gallery {
  padding: 5rem 0;

  .home-gallery-heading {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    color: $primary;
    margin: 1rem 0;
    letter-spacing: $letter-spacing;
  }
}

.home-stats {
  background-image: url("../../asset/home/home-banner-2.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .home-stat-bg-dark {
    padding: 5rem 0;
    background-color: #000000b3;
    .home-stats-no {
      font-size: 4rem;
      font-weight: 600;
      color: $light;
      text-align: center;
    }
    .home-stats-title {
      font-size: 1.2rem;
      color: $light;
      text-align: center;
    }
  }
}
.home-board-member-name {
  font-size: 1rem;
}
.home-gallery-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;

  .home-gallery-content-image {
    width: 30%;
    min-height: 100%;
    padding: 1rem;
    border-radius: 30px;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.01);
      box-shadow: 0px 0px 7px 0px #00000054;
    }
  }
}

.home-aboutus-banner {
  padding: 5rem 0;
  background-color: #ededed;

  .home-aboutus-title {
    text-align: center;
    font-size: $font-size-xl;
    font-weight: 600;
    color: $primary;
    padding: 2rem 0;
    letter-spacing: $letter-spacing;
  }
  .home-about-us-image img {
    width: 100%;
    border: 5px solid $secondary;
    padding: 0.5rem;
  }
  .home-aboutus-description {
    text-align: center;
    padding: 2rem 0;
  }
  .home-aboutus-h3 {
    font-size: 2.5rem;
    font-weight: 600;
    color: $primary;
    padding: 1rem 0;
    letter-spacing: $letter-spacing;
  }
  .home-aboutus-p {
    font-size: 1.2rem;
    color: $secondary;
    padding: 1rem 0;
    line-height: 2rem;
  }
  .home-more-aboutus {
    background-color: $info;
    padding: 1rem 0;
    color: $light;
    font-weight: 600;
    &:hover {
      color: $dark;
    }
  }
}

.home-member-main {
  padding: 5rem 0;
  .home-board-member {
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .home-board-member-image img {
      width: 10rem;
      transition: transform 0.5s ease-in-out;
      &:hover {
        transform: scale(0.8);
      }
    }
  }

  .home-member-title {
    text-align: center;
    font-size: $font-size-xl;
    font-weight: 600;
    color: $primary;
    padding: 1rem 0;
    letter-spacing: $letter-spacing;
  }
  .home-board-member-card {
    padding: 1rem;
    border: 1px solid #0296e5;
    border-radius: 2rem;
    width: 23%;
  }
}

.home-become-member {
  padding: 5rem 0;
  background-color: #ededed;
  .home-become-member-container {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .home-become-member-call {
    h1 {
      text-align: center;
      font-size: 3rem;
      font-weight: 600;
      color: $primary;
      padding: 2rem 0;
      letter-spacing: $letter-spacing;
    }
    p {
      text-align: center;
      font-size: 1.5rem;
      color: $secondary;
    }
  }
  .home-become-member-btn {
    height: $font-size-xl;
    width: 10rem;
    border: 1px solid $transparent;
    background-color: #f42a1c;
    color: $light;
    font-weight: 600;
    &:hover {
      background-color: $secondary;
      color: $light;
    }
  }
}

.home-become-member-call {
  padding: 1rem 0 !important;
}

@media screen and (max-width: 850px) {
  .home-motto-components {
    flex-direction: column;
    align-items: center;
  }
  .home-board-member {
    flex-direction: column !important;
    align-items: center;

    .home-board-member-card {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .main-home-stats {
    flex-direction: column !important;
  }
  .main-home-stats {
    background-color: rgba(0, 0, 0, 0.253) !important;
  }
  .home-gallery-content {
    flex-direction: column !important;
    .home-gallery-content-image {
      width: 100% !important;
    }
  }
  .home-motto-heading {
    p {
      font-size: 1.2rem !important;
    }
    h1 {
      font-size: 2rem !important;
    }
  }
  .home-mission-banner-heading-h1 {
    font-size: 2.5rem !important;
  }
  .home-gallery-heading {
    font-size: 2rem !important;
  }
  .home-aboutus-title {
    font-size: 2rem !important;
  }
  .home-aboutus-h3 {
    font-size: 1.5rem !important;
  }
  .home-member-title {
    font-size: 2rem !important;
  }
  .home-become-member-call {
    h1 {
      font-size: 2rem !important;
    }
    p {
      font-size: 1.2rem !important;
    }
  }

  .home-gallery {
    padding: 1rem 0 !important;
  }
  .home-aboutus-banner {
    padding: 1rem 0 !important;
  }
  .home-become-member {
    padding: 1rem 0 !important;
  }
}
