.cards-main-container {
  border-radius: 1rem;
  box-shadow: 0px 0px 70px 1px #3a333340;
  padding: 2rem;
  width: 30%;
  height: 20rem;
  justify-content: space-around;
  button {
    height: 3rem;
    width: 10rem;
    border-radius: 0.6rem;
    border: 1px solid $dark;
    color: $dark;
    font-weight: 600;
    &:hover {
      background-color: $secondary;
      color: $light;
    }
  }
}

.card-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: $secondary;
  padding: 1rem 0;
  text-align: center;
}

.card-description {
  font-size: 1.2rem;
  color: $tertiary;
  padding: 1rem 0;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .cards-main-container {
    width: 50%;
    margin: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .cards-main-container {
    width: 100%;
    margin: 1rem;
  }
}
