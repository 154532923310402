.zoomimage-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  height: 100%;
  backdrop-filter: blur(4px);
  z-index: 9999;
}
.zoomimage-main-span {
  position: fixed;
  top: 2rem;
  right: 2rem;
  color: $primary;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
  }
}

.zoom-main-iamge {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.3rem;
}

.zoom-all-images {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: auto;
  height: 100%;
  width: 100%;

  scroll-behavior: smooth;

  scroll-snap-type: x mandatory;
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  scroll-padding: 0px;

  img {
    min-width: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    object-fit: contain;
  }
}

.zoom-all-images::-webkit-scrollbar {
  display: none;
}

.zoomimage-click-btn {
  position: absolute;

  left: 0;
  top: 50%;

  span {
    height: 5rem;
    width: 5rem;
    background: $transparent;
    border-radius: 50%;
    color: $primary;
    cursor: pointer;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}
