.missioncards-main-container {
  //   box-shadow: 0px 0px 60px 0px #00000026;
  border-radius: 12px;
  padding: 0.5rem;
  margin: 0.5rem 0;
  height: 25rem;
}

.missioncards-image img {
  height: 100%;
  width: 25rem;
  object-fit: cover;
  filter: drop-shadow(2px 4px 6px #00000075);
  border-radius: 6px;
  margin-right: 1rem;
  border-radius: 50%;
}

.missioncards-contents-right {
  height: 100%;
  justify-content: space-around;
  padding: 1rem 0;
  width: 60%;
  .missioncards-title {
    font-size: 2.3rem;
    font-weight: bold;
    color: $dark;
    margin-bottom: 0.5rem;
  }
  .missioncards-description {
    font-size: 1.2rem;
    color: $tertiary;
    margin: 0.5rem;
    line-height: 2rem;
  }
  .missioncards-btn-goals {
    button {
      margin: 0 1rem;
      &:hover {
        background-color: $info !important;
        color: $light !important;
        border-color: $transparent !important;
      }
    }
  }
}

.missioncards-span {
  font-size: 2rem;
  padding: 1rem 0;
}

@media screen and (max-width: 850px) {
  .missioncards-main-container {
    flex-direction: column !important;
    align-items: center;
    height: 100% !important;
  }
  .missioncards-title {
    text-align: center;
  }
  .missioncards-description {
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .missioncards-contents-right {
    width: 100% !important;
  }
  .missioncards-image img {
    width: 20rem !important;
    margin: 0 !important;
  }
  .missioncards-btn-goals {
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 450px) {
  .missioncards-image img {
    width: 15rem !important;
    margin: 0 !important;
  }
  .missioncards-title {
    font-size: 1.5rem !important;
  }
}
