.news-main-container {
  padding: 1rem;
  border: 1px solid #c9c6c6;
  box-shadow: 0px 0px 18px 0px #00000057;
  margin: 3rem 0;
}
.news-heading {
  padding: 3rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  color: $primary;
  h1 {
    width: 70%;
    text-transform: capitalize;
    margin: 0 auto;
  }
}
.news-pdf {
  min-height: 100vh;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .news-heading {
    padding: 1rem 0 !important;
    font-size: 1rem !important;

    h1 {
      width: 100% !important;
      text-transform: capitalize;
      margin: 0 !important;
    }
  }
}
