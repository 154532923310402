.donatenow-main-head-banner {
  background-image: url("../../asset/common/common-banner-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 22rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .donatenow-main-head-banner-h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $light;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    &::after {
      content: "";
      display: block;
      border-bottom: 5px solid $primary;
    }
  }
}

.donate-now-account-details {
  background-color: $secondary;
  color: $light;
  padding: 2rem 0;
  .account-details-h1 {
    font-size: 2rem;
    padding: 2rem 0;
  }
  .donate-now-account-info {
    padding: 2rem 0;

    .donate-now-account-info-span {
      padding: 1rem 1rem;

      border-radius: 1rem;
      background: linear-gradient(45deg, #021b34, rgb(104 14 14 / 44%));
      h3 {
        color: $light;
      }
      span {
        font-weight: 500;
        color: $light;
      }
    }
  }
}

.donate-now-main-section {
  background-color: $light;
  padding: 3rem 0;
  .donatenow-main-section-container {
    padding: 0 10rem;
    box-shadow: 0px 0px 10px 0px #00000030;
    .donatenow-container-text-h1 {
      font-size: 1.5rem;
      padding: 3rem 0;
      text-align: center;
    }
    .donatenow-basic-input {
      .donatenow-basic-input-textfield {
        flex-wrap: wrap;
        width: 50%;
      }
    }
    .donatenow-message-box-field {
      width: 100%;
    }
  }
  .donatenow-btn {
    button {
      height: 3.5rem;
      width: 12rem;
      border-radius: 0.5rem;
      background-color: $primary;
      color: $light;

      font-weight: 600;
      &:hover {
        background-color: $secondary;
        color: $light;
      }
    }
  }
}

.donation-container {
  padding-bottom: 6rem;
}

@media screen and (max-width: 900px) {
  .donatenow-main-head-banner-h1 {
    font-size: 2rem !important;
  }
  .donatenow-main-section-container {
    padding: 0 5rem !important;
  }
}

@media screen and (max-width: 700px) {
  .donatenow-main-head-banner-h1 {
    font-size: 1.5rem !important;
  }
  .donatenow-main-section-container {
    padding: 0 1rem !important;
  }
  .donate-now-main-section {
    padding: 3rem 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .donatenow-main-head-banner {
    height: 12rem !important;
  }
  .donatenow-main-head-banner-h1 {
    font-size: 1rem !important;
  }
  .donatenow-basic-input {
    flex-direction: column !important;
  }
  .donatenow-basic-input-textfield {
    width: 100% !important;
  }
  .donate-now-account-info-span {
    h3 {
      font-size: 1rem !important;
    }
  }
}
