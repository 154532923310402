.media-main-container {
  padding-bottom: 3rem;
}
.media-fb-timeline {
  padding: 2rem 0;
}
.fb-heading {
  color: $primary;
  padding: 2rem 0;
}
.tablet {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.tablet:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.tablet:after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet .content {
  width: 100%;
  height: 100%;
  background: white;
  margin: -1px;
}

.media-p {
  padding: 2rem 0;
}
.media-heading-h1 {
  h1 {
    padding: 2rem 0;
    color: $primary;
    font-size: 2rem;
    text-transform: capitalize;
    text-align: center;
  }
}
.twitter-media,
.instagram-media,
.linkedin-media,
.facebook-media {
  height: 4rem;
  width: 22rem;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 9px;
  filter: drop-shadow(0px 0px 5px #000000c2);
  margin: 1rem 0;
}

.twitter-media {
  background: #0095ff;
}
.instagram-media {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}
.linkedin-media {
  background: #0048b5;
}
.facebook-media {
  background: #3b5998;
}
.media-follow {
  color: $light !important;

  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
  a {
    color: $light;
    display: flex;
    // align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
.media-icon {
  svg {
    height: 3rem;
    width: 3rem;
    color: $light;
  }
}

.other-media {
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.all-media-container {
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .media-main-container {
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: 600px) {
  .media-heading-h1 {
    h1 {
      font-size: 1.5rem !important;
    }
  }
  .other-media {
    flex-direction: column !important;
    align-items: center !important;
  }
  .content {
    iframe {
      width: 100% !important;
    }
  }
  .fb-heading {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 500px) {
  .all-media-container {
    gap: 1rem !important;
    justify-content: center !important;
  }
}
