.footer-main {
  background-color: #2c4854;
  color: $light;
  padding: $base-padding 0;
  li {
    padding-bottom: 0.5rem;
    a {
      color: $light;
    }
    &:hover {
      a {
        color: $danger;
      }
    }
  }
}

.footer-container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  h1 {
    color: $light;
    padding: $base-padding 0;
  }
}

.footer-heading {
  width: 30%;
  h1 {
    color: $light;
    padding: $base-padding 0;
  }
  p {
    color: $light;
    line-height: 1.7rem;
  }
}

.footer-social-icons {
  ul {
    display: flex;
    flex-direction: row;
    li {
      padding-right: 0.5rem;
      a {
        color: $light;
      }
    }
  }
}
.footer-social-div {
  display: flex;
  flex-direction: column;
}

.footer-newsletter {
  display: flex;
  // align-items: center;
  position: relative;
}
.footer-navlink {
  border: none !important;
}
//material ui css

.css-a88p61-MuiInputBase-root-MuiInput-root::before {
  border-bottom: none !important;
}
.css-a88p61-MuiInputBase-root-MuiInput-root::after {
  border-bottom: none !important;
}

.newsletter-input {
  div {
    // padding: 0.5rem;
    border-bottom: none;
    &:hover {
      border-bottom: none;
    }
  }
  input {
    color: #000000;
    background-color: $light;
    width: 17rem;

    border: 1px solid $transparent !important;
    border-radius: 5px;
    padding: 0.5rem;

    &:focus {
      border: 1px solid $light;
    }
  }
}

.footer-newsletter-sub-button {
  background-color: #f62b1d !important;
  border: 1px solid $light !important;
  color: $light !important;
  height: 2.5rem;
  // border-radius: 2rem !important;
  font-weight: bold !important;
  position: absolute;
  right: 105px;
  &:hover {
    background-color: #2c4854 !important;
    // border: 1px solid transparent !important;
    color: $light !important;
  }
}
.footer-heading-desc {
  padding: 1rem 0;
}
.footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
}
.footer-newsletter-main {
  margin-top: 1rem;
}
.footer-newsletter-main-p {
  color: $light;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}
.footer-heading-logo {
  a {
    border: none !important;
  }
}
@media screen and (max-width: 900px) {
  .footer-social-div {
    flex-direction: column-reverse !important;
  }
  .footer-container {
    flex-direction: column !important;
    .footer-heading {
      width: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .footer-main {
    padding: 0 !important;
  }
  .footer-copyright {
    padding: 2rem 0 !important;
  }
}

@media screen and (max-width: 450px) {
  .footer-social {
    width: 100% !important;
  }
  .footer-newsletter {
    flex-direction: column !important;
    gap: 0.5rem !important;
    div {
      width: 100% !important;
    }
  }
  .footer-newsletter-sub-button {
    right: 0 !important;
    width: 100% !important;
  }
  .newsletter-input input {
    width: 100% !important;
  }
  .footer-copyright {
    padding: 1rem 0 !important;
    p {
      text-align: center !important;
    }
  }
}
